import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе гольф-клуб Glow
			</title>
			<meta name={"description"} content={"Відчуйте блаженство гри в гольф і насолоджуйтеся моментом на Glow, де кожен удар розповідає історію"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе гольф-клуб Glow"} />
			<meta property={"og:description"} content={"Відчуйте блаженство гри в гольф і насолоджуйтеся моментом на Glow, де кожен удар розповідає історію"} />
			<meta property={"og:image"} content={"https://ua.glowsaup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.glowsaup.com/img/4522234.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.glowsaup.com/img/4522234.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.glowsaup.com/img/4522234.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.glowsaup.com/img/4522234.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.glowsaup.com/img/4522234.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.glowsaup.com/img/4522234.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Про нас
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Наша сутність
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Гольф-клуб "Сансет Рідж" - це маяк для тих, хто цінує гру в гольф та красу природи, що переплітаються між собою. Це не просто місце для гри, це святилище, де пристрасть до гольфу виходить за рамки буденності, запрошуючи гравців взяти участь у грі, яка непідвладна часу і постійно розвивається. Наша суть полягає у створенні атмосфери, в якій кожен візит відчувається як подорож крізь серце гольф-раю.
					</Text>
				</Box>
				<Image src="https://ua.glowsaup.com/img/3.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://ua.glowsaup.com/img/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Дух Glow
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Задуманий з любові до гри та поваги до землі, гольф-клуб Glow втілює дух гольфу в його найчистішій формі. Тут у гольф не просто грають - тут його святкують, на тлі приголомшливих пейзажів, які посилюють драматизм і виклик кожної лунки. Це місце, де традиції поєднуються з фантазією, а дух гольфу живе на кожному фервеї та ґріні.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому наша сутність має значення
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Полотно природної краси
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Кожне поле в Glow ретельно розроблене, щоб доповнити природне оточення, пропонуючи не просто гру, а досвід, який захоплює і кидає виклик.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Сутінковий досвід гри в гольф
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Унікальна для Glow вечірня гра під заворожуючими відтінками призахідного сонця пропонує неперевершений досвід гри в гольф, поєднуючи спорт із вражаючим мистецтвом природи.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Гостинність понад усе
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Від нашого клубу до зелених насаджень, ми гарантуємо, що кожен гість зустрінеться з неперевершеним сервісом, пропонуючи місце комфорту та спілкування.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Прагнення до досконалості
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						Наше прагнення підтримувати ідеальні умови для гри та пропонувати першокласні зручності відображає нашу відданість досконалості та повагу до гри.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});